function Projects() {
  

    return (
      <div className="Projects">
        <p>Lorem Ipsum</p>
      </div>
    );
  }
  
  export default Projects;
  